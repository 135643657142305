import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import TestmonialsPresenter from "./testimonials"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      jcsSrc: file(relativePath: { eq: "jcs-logo.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sazaeSrc: file(relativePath: { eq: "sazae-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      brainSrc: file(relativePath: { eq: "brain-power-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const TESTIMONIALS = [
    {
      name: "Peter Gibson",
      title: "Executive Advisor Tokyo 2020 Australian Olympic Committee ",
      comment:
        "It was a pleasure to co-MC with Haruka. Haruka is an incredibly talented bilingual professional. Very polished in either English and Japanese. I would have no hesitation in recommending her for any type of MC work.",
    },
    {
      name: "Yukiko Hirano",
      title: "JCS Rainbow Project President",
      comment:
        "準備段階からしっかりと計画して細かく司会の内容を詰めて下さったので、本当に素晴らしい司会になりました。又話し方や声のトーンもわかりやすく聞きやすく申し分のない司会でした。映画のナレーションも声が透き通っていて、トーンもシーンに合っていたので、映画を一層に引き立てていました。他にも司会やナレーションの要望があればはるかさんを是非推薦致します。",
      media: data.jcsSrc,
    },
    {
      name: "Ayumi Mizoshiri",
      title: "Director, Sazae Pty Ltd",
      comment:
        "とてもプロフェッショナルなサービスでイベントが盛り上がることができました。また機会がありましたらぜひお願いしたいと思います。",
      media: data.sazaeSrc,
    },
    {
      name: "Löeby Lovato",
      title: "Director/Media Manager/PM",
      comment:
        "Can't thank Haruka enough for bringing my script to life with her right modulation, voice intensity, adaptation of rhythm of speech and more importantly her 'easy going' attitude to work with. I look forward to working with Haruka soon, and I'd recommend her to anyone in need of a bilingual Japanese-English MC and or (VO)Voice over calls. Arigato!",
      media: data.brainSrc,
    },
    {
      name: "Hideki Konno",
      title: "Avain Australia",
      comment:
        "今回はスタッフ管理システム及び打刻システムの説明動画でのナレーション依頼をさせてもらいました。とても綺麗な英語と日本語で大変感動しました。クライアントからも、とてもクリアでわかりやすい説明と聞きました。次もぜひお願いしたいと思っています。 ありがとうございました😊",
    },
    {
      name: "Maki Watabe",
      title: "Account Planning Manager, doq Pty Ltd",
      comment:
        "今回司会者を手配するのが初めてだったので、Harukaさんに台本の準備や豪州でのイベントについての助言をいただきとても助かりました。また、クライアント側も大変満足されていましたので、感謝しかないです！完璧なご対応でした。",
    },
    {
      name: "渡邉　百合",
      title: "リコージャパン株式会社",
      comment:
        "ゴールドコーストでの表彰式では大変お世話になりました。事前にリモートでの打合せにも対応してくださり、安心してお任せすることができました。また、司会だけでなく、会全体の進行にも配慮いただき大変助かりました。新野さんのスムーズな進行により、滞りなく終えることができましたし、招待者の皆様にも栄誉を感じていただける式典となりました。ご協力いただき、ありがとうございました。機会がありましたら、またご一緒できると嬉しいです。",
    },
    {
      name: "Yoshimi Itoda",
      title: "NNA Australia Manager, Marketing & Editor",
      comment:
        "クライアントととの打ち合わせの段階から入って頂き、目的や目標、流れが共有できたので、非常に有りがたかったです。困難な状況下でもベストパフォーマンスを発揮してくれました。画面が華やぎ、ＭＣのおかげで雰囲気も明るいものとなりました。",
    },
    {
      name: "Miki Takechi",
      title: "IHI Engineering Australia Pty Ltd.",
      comment:
        "事前の打ち合わせ段階から当日の司会進行まで、令賀さんのプロ意識が高いお仕事ぶりに大変満足致しました。何度も経験していらっしゃるからこそのアイディアだったり、細かい所への気配りや配慮、当日に起きた想定外のハプニングにも一切動じる事なく機転を利かせて対応して下さり、本当にとても心強かったです。来場者の方々からの評判も非常に良かったです。またこういった機会がありましたら、是非迷わずお願いしたいです!!この度は本当にありがとうございました!!",
    },
    {
      name: "Kota Toda",
      title: "JTB Australia",
      comment:
        "新野さんはスクリプトをご自身の言葉にして、プロフェッショナルかつ臨機応変にMCをしていただけるところが、群を抜いて素晴らしいと思いました。 また、原稿やマイク位置など二転三転としてしまいましたが、新野さんのご視点から事前にご意見もいただき、現場でも確認ができとても助かりました。 改めて全体を通してサポートいただきまして、誠にありがとうございました。 またご一緒できることを楽しみにしております。",
    },
    {
      name: "Jason Khoh",
      title: "Managing Director, Mochiwa Mochiya Pty Ltd",
      comment:
        "Mochiwa Mochiya engaged Haruka san's MC services for the Reconstruction Agency's first-ever event in Australia, entitled Genki! Fukushima, on 14 December 2024 at the JAPAN EXPO in Sydney Town Hall. Haruka san was polite and professional throughout. Her engaging performance on stage ensured that onlookers enjoyed the presentation until the very end. Highly recommended!!",
    },
  ]

  return <TestmonialsPresenter testimonials={TESTIMONIALS} />
}

export default Testimonials
